import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import moment from 'moment'
import cookie from 'react-cookies'
import queryString from 'query-string'

import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase'
import { setViewMode, setActiveView } from '../redux/actions/proposalsActions'
import Zoom from './Zoom'
import * as Helper from '../util/Helper.js'
import Element from './Element'
import Elements from './Elements'
import Sidebar from './Sidebar'
import ViewMode from '../proposals/ViewMode'
import ManageRatings from './ManageRatings'
import MultipleLinesChart from './MultipleLinesChart'
import { rating_fields } from './rating_fields'
// import { rating_fields, rating_fields_for_specialist } from './rating_fields'
import CollaborativeReview from './CollaborativeReview'
import ThreeDModel from './3dModel'
import { withTranslation } from 'react-i18next'
import ElementWith3dView from './ElementWith3dView/ElementWith3dView'
import { adminRoles } from '../constant'
import FilterProposalsByPrivateTags from '../proposals/filter-proposals-by-tag/FilterProposalsByPrivateTags.js'
// import ManageRatingsForSpecialists from './ManageRatingsForSpecialists'
// import MultipleLinesChartForSpecialists from './MultipleLinesChartForSpecialists'

const styles = theme => ({
  elements: {
    flex: 1,
    padding: '24px'
  },
  button: {
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1)',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  }
})

class SingleProposal extends Component {
  state = {
    proposal: null,
    zoom: 2,
    isModalOpen: false,
    is3dViewModalOpen: false,
    singleElement: null,
    editClassesModal: false,
    date: null,
    expand: true,
    fullElement: false,
    isRatingModalOpen: false,
    isAllRatingsModalOpen: false,
    isRatingModalForSpecialistOpen: false,
    isAllRatingsModalForSpecialistOpen: false,
    rateSaved: false,
    view: 'elements'
  }

  zoomIn = () => {
    const { zoom } = this.state
    if (zoom < 6) {
      this.setState({ zoom: zoom + 1 })
    }
  }

  zoomOut = () => {
    const { zoom } = this.state
    if (zoom > 1) {
      this.setState({ zoom: zoom - 1 })
    }
  }

  openRatingModal = () => this.setState({ isRatingModalOpen: true })
  openAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: true })
  openRatingModalForSpecialist = () =>
    this.setState({ isRatingModalForSpecialistOpen: true })
  openAllRatingsModalForSpecialist = () =>
    this.setState({ isAllRatingsModalForSpecialistOpen: true })

  closeRatingModal = () => this.setState({ isRatingModalOpen: false })
  closeAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: false })
  closeRatingModalForSpecialist = () =>
    this.setState({ isRatingModalForSpecialistOpen: false })
  closeAllRatingsModalForSpecialist = () =>
    this.setState({ isAllRatingsModalForSpecialistOpen: false })

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('popstate', this.handlePopState)

    const readOnly = process.env.REACT_APP_READ_ONLY
    if (!readOnly) {
      const { addToDataBase, profile, userId, match } = this.props
      const savedProposal = _.get(profile, `proposals.${match.params.id}`, {})
      const currentDate = moment().format('lll')
      if (savedProposal.date) {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: savedProposal.date } }
        })
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } }
        })
      } else {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: currentDate } }
        })
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } }
        })
      }
    }

    const proposal = this.getProposal()

    proposal && this.setState({ proposal }, this.updateViewModeState)
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      proposals: { cookies, data }
    } = this.props
    const {
      location: prevLocation,
      proposals: { cookies: prevCookies }
    } = prevProps
    const proposal = this.getProposal()

    if (!_.isEmpty(data) && !this.state.proposal && !this.state.singleElement) {
      // Fix elements of undefined when refresh from SingleProposal or SingleElement
      this.setState({ proposal }, this.updateViewModeState)
    }
    if (location.search !== prevLocation.search) {
      this.updateViewModeState()
    }
    if (location.pathname !== prevLocation.pathname) {
      this.setState({ proposal }, this.updateViewModeState)
    }
    if (cookies) {
      const cookiesQuery = queryString.parse(cookies.replace(/; /g, '&'))
      const prevCookiesQuery = queryString.parse(
        prevCookies.replace(/; /g, '&')
      )
      if (
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page') &&
        !this.state.fullElement
      ) {
        setTimeout(() => this.fullElementMode(proposal), 500)
      }
      if (
        cookiesQuery.j_count !== prevCookiesQuery.j_count &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        setTimeout(
          () => this.fullElementMode(proposal, parseInt(cookiesQuery.j_count)),
          500
        )
      }
      if (
        cookiesQuery.j_proposal !== prevCookiesQuery.j_proposal &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        this.setState({ fullElement: false })
        this.props.history.replace(`/proposal/${cookiesQuery.j_proposal}`)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState)
  }

  handlePopState = () => {
    window.scrollTo(0, 0)
  }

  fullElementMode = (proposal, count = 0) => {
    if (this.props.viewMode === 'normal') return
    const keys = Object.keys(proposal.elements)
    const pageName = window.name

    this.setState({ fullElement: true })
    if (count >= 0) {
      this.props.history.replace({
        search: null
      })
    }

    if (pageName === 'page 1') {
      this.openSingleElement(keys[count], keys.indexOf(keys[count]))
    }
    if (pageName === 'page 2') {
      this.openSingleElement(keys[count + 1], keys.indexOf(keys[count + 1]))
    }
    if (pageName === 'page 3') {
      this.openSingleElement(keys[count + 2], keys.indexOf(keys[count + 2]))
    }
  }

  openSingleElement = (elementKey, index) => {
    const { history } = this.props
    const query = queryString.parse(this.props.location.search)

    if (elementKey && !query.view_mode) {
      history.replace({
        search: `view_mode=true&start_key=${elementKey}&start_index=${index}`
      })
    }
  }

  getStartKey = (start_key, start_index) => {
    const { proposal } = this.state
    if (!proposal) return
    const query = queryString.parse(this.props.location.search)
    let historyObj = { search: '' }

    if (start_key && Object.keys(proposal.elements).includes(start_key)) {
      return start_key
    } else if (start_index < Object.keys(proposal.elements).length) {
      return Object.keys(proposal.elements)[start_index]
    }
    if (query.view_mode) {
      historyObj.search = 'view_mode=true&start_index=0'
    }
    this.props.history.replace(historyObj)

    return Object.keys(proposal.elements)[0]
  }

  updateViewModeState = () => {
    const { proposal } = this.state
    const query = queryString.parse(this.props.location.search)
    const startKey = this.getStartKey(query.start_key, query.start_index)

    if (query.view_mode) {
      this.setState({
        isModalOpen: true,
        singleElement: proposal.elements[startKey],
        id: startKey
      })
    } else {
      this.setState({
        isModalOpen: false
      })
    }
  }

  closeModal = () => {
    const { history, viewMode, setViewMode } = this.props

    // Close full view mode modal
    if (viewMode === 'full') setViewMode('normal')
    cookie.remove('JUG_MODE', { path: '/' })
    this.setState({ isModalOpen: false, fullElement: false })

    history.replace({
      search: ''
    })
  }

  closeEditCalssesModal = () => this.setState({ editClassesModal: false })
  OpenEditCalssesModal = () => this.setState({ editClassesModal: true })

  getProposal = () => {
    const { proposals, match } = this.props

    // Redirect user to homepage when single proposal is not found:
    // 1. Missing proposal
    // 2. User refreshes single proposal page when viewing proposal in phase 2
    if (proposals?.data) {
      if (!proposals.data[match.params.id]) {
        return this.props.history.replace('/')
      }
      return proposals.data[match.params.id]
    }
  }

  toggleSidebar = () => {
    this.setState({ expand: !this.state.expand })
  }

  toggleView = viewType => {
    this.props.setActiveView(viewType)
  }

  copyAveragesRateToPublicRate = (averageRate, proposalId, documentId) => {
    Helper.updatePublicRate(
      averageRate,
      proposalId,
      documentId,
      this.props,
      () => this.setState({ rateSaved: true })
    )
  }

  saveRates = (rate, proposalId, documentId) => {
    const { profile } = this.props
    if (adminRoles.includes(profile.role)) {
      Helper.updatePublicRate(rate, proposalId, documentId, this.props, () =>
        this.setState({ rateSaved: true })
      )
    } else {
      Helper.updatePrivateRate(rate, proposalId, this.props, () =>
        this.setState({ rateSaved: true })
      )
    }
  }

  unsetRateSaved = () => {
    this.setState({ rateSaved: false })
  }

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY
    const {
      classes,
      proposals,
      PublicClasses,
      profile,
      match,
      proposalsData,
      selectedPhase,
      users,
      userId,
      t
    } = this.props
    const {
      expand,
      proposal,
      fullElement,
      isAllRatingsModalOpen,
      rateSaved,
      view,
      is3dViewModalOpen
    } = this.state
    const proposalId = match.params.id
    const proposalClass = Helper.getProposalClass(
      proposalId,
      proposalsData,
      PublicClasses
    )
    const proposalPrivateRate = Helper.getProposalPrivateRate(
      profile,
      proposalId
    )
    const proposalPublicRate =
      isLoaded(proposalsData) &&
      Helper.getProposalPublicRate(proposalsData, proposalId)
    // const proposalPublicRateForSpecialists =
    //   isLoaded(proposalsData) &&
    //   Helper.getProposalPublicRateForSpecialists(proposalsData, proposalId)
    const proposalAllRate = Helper.getProposalAllRate(users, proposalId)
    // const proposalAllRateForSpecialists = Helper.getProposalAllRateForSpecialists(
    //   users,
    //   proposalId
    // )
    const averageRate = proposalAllRate.rate ? proposalAllRate.rate : {}
    // const averageRateForSpecialist = proposalAllRateForSpecialists.rate
    //   ? proposalAllRateForSpecialists.rate
    //   : {}

    if (proposals.loading || _.isEmpty(proposals.data) || _.isEmpty(proposal)) {
      return <div />
    }

    return (
      proposal && (
        <>
          <div style={{ display: 'none' }}>
            <FilterProposalsByPrivateTags />
          </div>
          <div className="d-flex">
            {/*all Elements*/}
            <div className={classes.elements}>
              <div className="d-flex justify-content-end align-items-center mt-1">
                {profile.role !== 'specialist' &&
                  view !== 'editor-for-specialist' && (
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={() =>
                        view === 'elements' || view === '3d-model'
                          ? this.setState({ view: 'editor-for-judges' })
                          : this.setState({ view: 'elements' })
                      }
                      style={{
                        backgroundColor:
                          view === 'editor-for-judges' ? '#3f51b5' : '#fff',
                        color:
                          view === 'editor-for-judges' ? '#fff' : '#3f51b5',
                        borderRadius: '0px'
                      }}
                      className={`mx-2 ${classes.button}`}
                    >
                      {view !== 'editor-for-judges'
                        ? adminRoles.includes(profile.role)
                          ? t('Open collaborative review for judges')
                          : t('Open collaborative review')
                        : adminRoles.includes(profile.role)
                        ? t('Close collaborative review for judges')
                        : t('Close collaborative review')}
                    </Button>
                  )}
                {/* {profile.role !== 'judge' && view !== 'editor-for-judges' && (
                  <Button
                    size="medium"
                    variant="outlined"
                    onClick={() =>
                      view === 'elements' || view === '3d-model'
                        ? this.setState({ view: 'editor-for-specialist' })
                        : this.setState({ view: 'elements' })
                    }
                    style={{
                      backgroundColor:
                        view === 'editor-for-specialist' ? '#3f51b5' : '#fff',
                      color:
                        view === 'editor-for-specialist' ? '#fff' : '#3f51b5',
                      borderRadius: '0px'
                    }}
                    className={`mx-2 ${classes.button}`}
                  >
                    {view !== 'editor-for-specialist'
                      ? adminRoles.includes(profile.role)
                        ? t('Open collaborative review for specialists')
                        : t('Open collaborative review')
                      : adminRoles.includes(profile.role)
                      ? t('Close collaborative review for specialists')
                      : t('Close collaborative review')}
                  </Button>
                )} */}
                {/* <Button
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    view === 'elements' || view === 'editor'
                      ? this.setState({ view: '3d-model' })
                      : this.setState({ view: 'elements' })
                  }
                  color="primary"
                  className="mx-2"
                >
                  {view === 'elements' || view === 'editor'
                    ? 'Open 3D model'
                    : 'Close 3D model'}
                </Button> */}
                <Link
                  to="/"
                  className="d-flex justify-content-end align-items-center"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000'
                  }}
                >
                  <ViewMode
                    toggleView={this.toggleView}
                    activeView=""
                    isSecondPhase={selectedPhase === 'phase-2'}
                  />
                </Link>
                {view === 'elements' ? (
                  <Zoom
                    zoomIn={this.zoomIn}
                    zoomOut={this.zoomOut}
                    zoomLevel={this.state.zoom}
                  />
                ) : null}
              </div>
              {view === 'elements' && (
                <div className="mt-3">
                  <Elements
                    single
                    openSingleElement={this.openSingleElement}
                    zoom={this.state.zoom}
                    elements={proposal.elements}
                    proposalId={match.params.id}
                    open3dView={event => {
                      event.stopPropagation()
                      this.setState({ is3dViewModalOpen: true })
                    }}
                  />
                </div>
              )}
              {view === 'editor-for-judges' && (
                <CollaborativeReview
                  proposalId={match.params.id}
                  userId={userId}
                  profile={profile}
                  databaseName="reviews"
                />
              )}
              {view === 'editor-for-specialist' && (
                <CollaborativeReview
                  proposalId={match.params.id}
                  userId={userId}
                  profile={profile}
                  databaseName="reviews-specialist"
                />
              )}
              {view === '3d-model' && (
                <ThreeDModel proposalId={match.params.id} />
              )}
            </div>

            <Sidebar
              expand={expand}
              proposal={proposal}
              profile={profile}
              readOnly={readOnly}
              proposalClass={proposalClass}
              proposalId={proposalId}
              toggleSidebar={this.toggleSidebar}
              OpenEditCalssesModal={this.OpenEditCalssesModal}
              openRatingModal={this.openRatingModal}
              openAllRatingsModal={this.openAllRatingsModal}
              openRatingModalForSpecialist={this.openRatingModalForSpecialist}
              openAllRatingsModalForSpecialist={
                this.openAllRatingsModalForSpecialist
              }
            />

            {/* The Element Modal */}
            {is3dViewModalOpen ? (
              <>
                <ElementWith3dView
                  close={() => this.setState({ is3dViewModalOpen: false })}
                  isOpen={is3dViewModalOpen}
                  expand={!expand}
                />
              </>
            ) : (
              <Element
                id={this.state.id}
                element={this.state.singleElement}
                elements={proposal.elements}
                proposalName={proposal.number}
                close={this.closeModal}
                isOpen={this.state.isModalOpen}
                expand={!expand}
                history={this.props.history}
                noSlider={fullElement}
                proposalsData={this.props.proposalsData}
                PublicClasses={this.props.PublicClasses}
              />
            )}
          </div>

          {/* Rating modal */}
          {isLoaded(proposalsData) &&
            isLoaded(profile) &&
            (adminRoles.includes(profile.role) || profile.role === 'judge') && (
              <ManageRatings
                title={
                  adminRoles.includes(profile.role)
                    ? t('Jury rating setup')
                    : t('My rating')
                }
                close={this.closeRatingModal}
                isOpen={this.state.isRatingModalOpen}
                proposal={proposal}
                fields={rating_fields}
                onSave={this.saveRates}
                proposalPrivateRate={
                  adminRoles.includes(profile.role)
                    ? proposalPublicRate
                    : proposalPrivateRate
                }
                secretary={adminRoles.includes(profile.role)}
                proposalAverageRate={proposalAllRate.rate}
                copyAveragesRate={() =>
                  this.copyAveragesRateToPublicRate(
                    averageRate,
                    proposalId,
                    'rate-for-judges'
                  )
                }
                match={match}
                rateSaved={rateSaved}
                unsetRateSaved={this.unsetRateSaved}
                ratingCriteria={this.props.judgesRatingCriteria}
                documentId="rate-for-judges"
                userRateFactor={profile.rateFactor}
              />
            )}

          {/* {isLoaded(proposalsData) &&
            isLoaded(profile) &&
            (adminRoles.includes(profile.role) ||
              profile.role === 'specialist') && (
              <ManageRatingsForSpecialists
                title={
                  adminRoles.includes(profile.role)
                    ? t('Experts’ rating setup')
                    : t('My rating')
                }
                close={this.closeRatingModalForSpecialist}
                isOpen={this.state.isRatingModalForSpecialistOpen}
                proposal={proposal}
                fields={rating_fields_for_specialist}
                onSave={this.saveRates}
                proposalPrivateRate={
                  adminRoles.includes(profile.role)
                    ? proposalPublicRateForSpecialists
                    : proposalPrivateRate
                }
                secretary={adminRoles.includes(profile.role)}
                proposalAverageRate={proposalAllRateForSpecialists.rate}
                copyAveragesRate={() =>
                  this.copyAveragesRateToPublicRate(
                    averageRateForSpecialist,
                    proposalId,
                    'rate-for-specialists'
                  )
                }
                match={match}
                rateSaved={rateSaved}
                unsetRateSaved={this.unsetRateSaved}
                ratingCriteria={this.props.specialistsRatingCriteria}
                documentId="rate-for-specialists"
                userRateFactor={profile.rateFactor}
              />
            )} */}

          {/* All Ratings Modal */}
          {adminRoles.includes(profile.role) && isAllRatingsModalOpen && (
            <MultipleLinesChart
              close={this.closeAllRatingsModal}
              isOpen={isAllRatingsModalOpen}
              allRates={proposalAllRate}
              proposal={proposal}
            />
          )}

          {/* {adminRoles.includes(profile.role) &&
            isAllRatingsModalForSpecialistOpen && (
              <MultipleLinesChartForSpecialists
                close={this.closeAllRatingsModalForSpecialist}
                isOpen={isAllRatingsModalForSpecialistOpen}
                allRates={proposalAllRateForSpecialists}
                proposal={proposal}
              />
            )} */}
        </>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    proposals: state.proposals,
    profile: state.firebase.profile,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsData: state.firestore.ordered.proposals,
    viewMode: state.proposals.viewMode,
    selectedPhase: state.proposals.selectedPhase,
    users: state.firestore.ordered.users,
    judgesRatingCriteria: state.firestore.ordered['RatingCriteria'],
    specialistsRatingCriteria:
      state.firestore.ordered['specialists-rating-criteria']
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) =>
      dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    setViewMode: viewMode => dispatch(setViewMode(viewMode)),
    setActiveView: activeView => dispatch(setActiveView(activeView))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' },
    { collection: 'users' },
    { collection: 'RatingCriteria' },
    { collection: 'specialists-rating-criteria' }
  ])
)(
  withSnackbar(withTranslation('proposals')(withStyles(styles)(SingleProposal)))
)
