import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'

import Text from './Text'
import DeleteFilterOption from './DeleteFilterOption'

const styles = theme => ({
  menuItem: {
    margin: '4px 8px',
    padding: '4px 8px'
  },
  menuPaper: {
    minWidth: 'max-content',
    maxWidth: '100%'
  },
  tag: {
    borderRadius: '50px',
    border: '1px solid transparent',
    marginLeft: '2px',
    marginRight: '2px',
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    minWidth: 20
  },
  includeTag: {
    borderColor: '#3f51b5'
  },
  excludeTag: {
    borderColor: 'red'
  }
})

const FilterOptions = ({
  value,
  options,
  onChange,
  classes,
  fixedWidth,
  deleteOption
}) => {
  const { t } = useTranslation('proposals')
  const option = options.find(option => option.value === value)
  const excludeOption = options.find(option => option.type === 'exclude')

  const renderTags = option => {
    return option.selectedTags.map(tag => (
      <div
        key={tag}
        className={`${classes.tag} ${
          excludeOption?.selectedTags?.includes(tag)
            ? classes.excludeTag
            : classes.includeTag
        }`}
      >
        {tag}
      </div>
    ))
  }

  return (
    <Select
      displayEmpty
      value={option?.value ? option?.value : 1}
      onChange={onChange}
      input={
        <Text
          style={{ minWidth: fixedWidth ? 'max-content' : '' }}
          id="select-multiple-checkbox"
          size="md"
        />
      }
      classes={{
        root: classes.root
      }}
      MenuProps={{
        classes: { paper: classes.menuPaper },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      }}
    >
      {options.map(option => {
        const tags = option?.selectedTags?.length ? renderTags(option) : null
        return (
          <MenuItem
            disableGutters={true}
            key={option.value}
            value={option.value}
            classes={{
              root: classes.menuItem
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <div
                className="d-flex full-width align-items-center"
                style={{ fontSize: 13 }}
              >
                {t(option.label)} {tags}: {option?.amount}
              </div>
              {option.type === 'include' ? (
                <DeleteFilterOption
                  option={option}
                  deleteOption={deleteOption}
                />
              ) : null}
            </div>
          </MenuItem>
        )
      })}
    </Select>
  )
}

FilterOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired
}

export default withStyles(styles)(FilterOptions)
