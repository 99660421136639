import React from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import RadioIcon from '@material-ui/icons/Lens'
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import Button from '../inputs/Button'
import CustomModal from '../modal/CustomModal'
import { withTranslation } from 'react-i18next'
import { rating_fields } from './rating_fields'

const styles = theme => ({
  rating_field: {
    fontSize: 14,
    paddingTop: '0.25rem',
    borderBottom: '1px solid #ecedee',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '2rem'
  },
  infoToolTip: {
    padding: '16px',
    fontSize: '14px'
  }
})

const infoIconContent = (
  <>
    <p>
      {'“Please, give rating for each topic which you are instructed to rate.”'}
    </p>
    <p>
      <i>
        {
          'Note: it is important to give rating to every proposal coherently. Topics that are left unrated will not affect the calculation of average value.'
        }
      </i>
    </p>
  </>
)

const StyledRating = withStyles({
  iconEmpty: {
    color: '#c7d1ff'
  },
  iconFilled: {
    color: '#394eb4'
  },
  iconHover: {
    color: '#394eb4'
  }
})(Rating)

const StyledRating2 = withStyles({
  iconEmpty: {
    color: '#bdbdbd'
  },
  iconFilled: {
    color: '#767676'
  },
  iconHover: {
    color: '#767676'
  }
})(Rating)

function generateInitialRate() {
  const rate = {}

  rating_fields.forEach(item => {
    rate[item.name] = null
  })

  return rate
}

class ManageRatings extends React.Component {
  state = {
    rate: generateInitialRate(),
    proposalId: null,
    updatedWithDb: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.proposalId) {
      return { proposalId: nextProps.match.params.id }
    } else return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { proposalPrivateRate, rateSaved, unsetRateSaved } = this.props
    // switch to new proposal, database rate is NOT empty
    const isNewProposal =
      prevState.proposalId !== this.state.proposalId &&
      !_.isEqual(proposalPrivateRate, this.state.rate)
    // rate state is not up to date with database rate (just land on page, or copying rate)
    const ratesNotUpdated =
      !_.isEqual(proposalPrivateRate, this.state.rate) && rateSaved
    const ratesIsUpdated =
      _.isEqual(proposalPrivateRate, this.state.rate) && rateSaved

    if (isNewProposal) {
      this.setState({ rate: proposalPrivateRate, updatedWithDb: true })
    } else if (ratesNotUpdated) {
      this.setState({
        rate: proposalPrivateRate,
        updatedWithDb: true
      })
      unsetRateSaved()
    }

    if (ratesIsUpdated) unsetRateSaved()

    // Automatic save when switch proposal
    if (prevState.proposalId !== this.state.proposalId && this.props.isOpen) {
      this.props.onSave(prevState.rate, prevState.proposalId)
    }
  }

  componentDidMount() {
    const { proposalPrivateRate, match } = this.props

    this.setState({ proposalId: match.params.id })
    this.setState({
      rate: { ...this.state.rate, ...proposalPrivateRate },
      updatedWithDb: true
    })
  }

  calculateOverallRatingByMultiplier = rateObj => {
    const { ratingCriteria, userRateFactor } = this.props

    if (!ratingCriteria) return

    const ratedCriteria = ratingCriteria.filter(item => !!rateObj[item.name])

    if (ratedCriteria.length === 0) return 0

    const weightedSum = ratedCriteria.reduce((acc, item) => {
      if (item.name !== 'judge_weighted_average_score') {
        return acc + rateObj[item.name] * Number(item.multiplier)
      }
      return acc
    }, 0)

    const totalMultiplier = ratedCriteria.reduce((acc, item) => {
      if (item.name !== 'judge_weighted_average_score') {
        return acc + Number(item.multiplier)
      }
      return acc
    }, 0)

    const judge_weighted_average_score =
      (weightedSum / totalMultiplier) * Number(userRateFactor)

    return Number(judge_weighted_average_score.toFixed(2))
  }

  getRatingFields = () => {
    const { fields, classes, proposalAverageRate, secretary, t } = this.props
    const { rate } = this.state
    // const ratingValues = !_.isEmpty(rate) ? rate[item.name] : 0;

    return fields.map((item, i) => {
      // Disable Overall rating for judge and use calculated average rating with multiplier
      if (item.name === 'judge_weighted_average_score' && !secretary) {
        return (
          <div key={i}>
            <div className={classes.rating_field}>
              <div
                style={{
                  width: 285,
                  fontWeight: rate[item.name] ? 'bold' : 'inherit'
                }}
              >
                <span>{t(item.label)}</span>
              </div>

              {proposalAverageRate && secretary && (
                <div>
                  <StyledRating2
                    name={t(item.label)}
                    value={proposalAverageRate[item.name] || 0}
                    precision={0.1}
                    icon={<RadioIcon fontSize="small" />}
                    readOnly
                  />
                </div>
              )}

              <div>
                <StyledRating2
                  name={t(item.label)}
                  value={rate[item.name] || 0}
                  precision={0.1}
                  icon={<RadioIcon fontSize="small" />}
                  readOnly
                />
              </div>
            </div>
            {i === 0 && <div className={classes.rating_field}></div>}
          </div>
        )
      }

      const handleChangeRating = (i, e, newValue) => {
        const newRate = _.merge({}, rate)
        newRate[item.name] = newValue

        newRate['judge_weighted_average_score'] =
          this.calculateOverallRatingByMultiplier(newRate) || 0
        this.setState({ rate: newRate })
      }

      return (
        <div key={i}>
          <div className={classes.rating_field}>
            <div
              style={{
                width: 285,
                fontWeight: rate[item.name] ? 'bold' : 'inherit'
              }}
            >
              <span>{t(item.label)}</span>
            </div>

            {proposalAverageRate && secretary && (
              <div>
                <StyledRating2
                  name={t(item.label)}
                  value={proposalAverageRate[item.name] || 0}
                  precision={0.1}
                  icon={<RadioIcon fontSize="small" />}
                  readOnly
                />
              </div>
            )}

            <div>
              <StyledRating
                name={t(item.label)}
                value={rate[item.name] || 0}
                precision={0.5}
                onChange={(e, newValue) => handleChangeRating(i, e, newValue)}
                onClick={e => this.handleClickRemoveRate(e)}
                icon={<RadioIcon fontSize="small" />}
              />
            </div>
          </div>
          {i === 0 && <div className={classes.rating_field}></div>}
        </div>
      )
    })
  }

  closeAndRest = () => {
    const { close } = this.props

    this.saveRating()
    close()
  }

  saveRating = () => {
    const { rate, proposalId } = this.state
    const { onSave, documentId } = this.props

    onSave(rate, proposalId, documentId)
  }

  onSaveHandler = () => {
    const { close } = this.props

    this.saveRating()
    close()
  }

  handleClickRemoveRate = e => {
    const { rate } = this.state
    const targetEleName = e.target.getAttribute('name')
      ? e.target.getAttribute('name')
      : null
    const targetEleValue = parseInt(e.target.getAttribute('value'))

    if (rate && targetEleName && targetEleValue) {
      _.forEach(rate, (value, key) => {
        const targetRateCriteria = rating_fields.find(
          rating => rating.name === key
        )
        if (
          targetEleName === targetRateCriteria?.label &&
          targetEleValue === value
        ) {
          this.setState({
            rate: {
              ...rate,
              [key]: null,
              judge_weighted_average_score: this.calculateOverallRatingByMultiplier(
                {
                  ...rate,
                  [key]: null
                }
              )
            }
          })
        }
      })
    }
  }

  copyRates = () => {
    this.props.copyAveragesRate()
  }

  clearAll = () => {
    this.setState({ rate: generateInitialRate() })
  }

  cancelAction = () => {
    // this.setState({ rate: this.props.proposalPrivateRate });
    this.props.close()
  }

  render() {
    const {
      title,
      isOpen,
      classes,
      proposal,
      proposalAverageRate,
      secretary,
      t
    } = this.props

    return (
      <CustomModal
        handleClose={this.closeAndRest}
        aria-labelledby="rating modal"
        show={isOpen}
        modalWidth={600}
        modalHeight={700}
      >
        <div className="p-3">
          <div className="mb-2">
            <div className="d-flex justify-content-between align-items-start">
              <h2>{title} </h2>
              <Tooltip
                title={infoIconContent}
                aria-label={infoIconContent}
                placement="left-start"
                TransitionComponent={Zoom}
                classes={{ tooltip: classes.infoToolTip }}
              >
                <InfoOutlineIcon />
              </Tooltip>
            </div>
            <h3>
              {t('Proposal')} {proposal.number}
            </h3>
          </div>

          {proposalAverageRate && secretary && (
            <div className="d-flex justify-content-end sm">
              <div style={{ width: 140 }}>
                {t('Judges’ weighted average score')}
              </div>
              <div style={{ width: 100 }}>
                {t('Jury’s final rating of judges’ w.a.s.')}
              </div>
            </div>
          )}

          <div className=" border-top">{this.getRatingFields()}</div>

          <div
            className={`d-flex justify-content-${
              secretary ? 'start' : 'end'
            } mt-2`}
            style={{ marginLeft: secretary ? '310px' : '0' }}
          >
            {secretary && (
              <div className="mr-2">
                <Button onClick={this.copyRates} size="xs" color="primary">
                  {t('Use averages')}
                </Button>
              </div>
            )}
            <Button onClick={this.clearAll} size="xs" color="primary">
              {t('Clear this rating')}
            </Button>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              size="md"
              variant="outlined"
              color="primary"
              onClick={this.cancelAction}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="md"
              variant="contained"
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={this.onSaveHandler}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </CustomModal>
    )
  }
}

export default withTranslation('proposals')(withStyles(styles)(ManageRatings))
