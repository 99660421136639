import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  listHeader: {
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px',
    backgroundColor: theme.modeColors.inputBackground
  },
  listFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    backgroundColor: theme.modeColors.inputBackground,
    borderTop: '1px solid #e0e0e0',
    width: '100%'
  },
  menuItem: {
    margin: '4px 8px',
    padding: '0px 8px',
    minHeight: 30,
    backgroundColor: theme.modeColors.inputBackground
  },
  checkbox: {
    padding: 0,
    paddingRight: 8
  },
  filterBtn: {
    border: '1px solid #3f51b5',
    borderRadius: 0,
    padding: '4px 7px 3px',
    color: '#3f51b5',
    backgroundColor: '#fff'
  },
  filterBtnActive: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    '&:focus': {
      borderRadius: 0,
      outline: 'none'
    }
  },
  menuPaper: {
    width: 'max-content'
  }
}))

export default useStyles
