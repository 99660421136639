import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Zoom from '@material-ui/core/Zoom'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined'
import { RiProhibitedLine } from 'react-icons/ri'

import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useTheme } from '@material-ui/styles'
import { Tooltip } from '@material-ui/core'

import Text from './Text'
import Button from './Button'
import useStyles from './MultipleSelectStyles'
import ProposalMatchCount from './ProposalMatchCount'
import ExcludeTag from '../proposals/filter-proposals-by-tag/ExcludeTag'

const MultipleSelectForPrivateTags = ({
  placeholder,
  options,
  values,
  onChange,
  selectAll,
  clearAll,
  apply,
  publicTagFilterBy,
  setPublicTagFilterBy,
  matchAllCount,
  matchAnyCount,
  matchExcludeCount,
  matchTags
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation('navbar')
  const [open, setOpen] = useState(false)
  const selectRef = useRef(null)
  const [menuWidth, setMenuWidth] = useState(undefined)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const applyChange = useCallback(() => {
    apply()
    handleClose()
  }, [apply, handleClose])

  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth)
    }
  }, [])

  const MenuProps = useMemo(
    () => ({
      classes: { paper: classes.menuPaper },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null,
      MenuListProps: {
        style: {
          position: 'relative',
          overflow: 'auto',
          backgroundColor: theme.modeColors.inputBackground
        }
      },
      PaperProps: {
        style: {
          maxWidth: '100%',
          minWidth: menuWidth ? menuWidth : 'auto'
        }
      }
    }),
    [classes.menuPaper, theme.modeColors.inputBackground, menuWidth]
  )

  return (
    <Select
      multiple
      displayEmpty
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={values}
      onChange={onChange}
      MenuProps={MenuProps}
      input={
        <Text active={values.length} id="select-multiple-checkbox" size="md" />
      }
      ref={selectRef}
      renderValue={selected => {
        if (selected.length === 0) {
          return <span>{placeholder}</span>
        }
        return selected.join(', ')
      }}
    >
      <MenuItem
        in={open ? 'true' : 'false'}
        value="none"
        className={classes.listHeader}
      >
        <Button onClick={selectAll} size="md" color="primary">
          {t('Select all')}
        </Button>
        <Button onClick={clearAll} size="md">
          {t('Clear all')}
        </Button>
      </MenuItem>
      <MenuItem>
        <Info />
      </MenuItem>
      {options.map((option, i) => (
        <MenuItem
          disableGutters
          key={i}
          value={option.name}
          classes={{
            root: classes.menuItem
          }}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={values.indexOf(option.name) > -1}
            classes={{
              root: classes.checkbox
            }}
            color="default"
          />
          <div className="d-flex full-width align-items-center">
            <div className="sm">{option.name}</div>
            <div className="d-flex ml-auto align-items-center">
              <div
                style={{
                  marginRight: 5
                }}
              >
                <ExcludeTag tagName={option.name} />
              </div>
              <div className="sm">{option.amount}</div>
              {option.color && (
                <div
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: option.color,
                    marginLeft: '8px'
                  }}
                />
              )}
            </div>
          </div>
        </MenuItem>
      ))}
      <MenuItem value="none" className={classes.listFooter}>
        {placeholder === t('Jury tags') ||
        placeholder === t('My private tags') ? (
          <ProposalMatchCount
            matchAllCount={matchAllCount}
            matchAnyCount={matchAnyCount}
            matchExcludeCount={matchExcludeCount}
            values={values}
            handleClose={handleClose}
            matchTags={matchTags}
          />
        ) : (
          <p className="sm pl-1 m-0">
            {t('Selected')}: {values.length}
          </p>
        )}
        {placeholder === t('Jury tags') ||
        placeholder === t('My private tags') ? null : (
          <Button
            variant="outlined"
            color="primary"
            onClick={applyChange}
            size="sm"
          >
            {t('Apply')}
          </Button>
        )}
      </MenuItem>
    </Select>
  )
}

export default MultipleSelectForPrivateTags

const Info = () => {
  const { t, i18n } = useTranslation('proposals')
  const currentLanguage = i18n.language

  const infoIconContent =
    currentLanguage === 'en' ? (
      <div>
        <p>
          Activate the tags that you want to use to filter suggestions. The
          suggestions that match all or any of the selected tags will be
          retrieved. The selection buttons display the number of suggestions
          that match your choices. Activating a tag and a prohibition symbol
          will exclude suggestions marked with that tag from the filtered
          results.
        </p>
        <p>
          You can create and edit proposal-specific tags in the proposal’s board
          view under the section: ‘My Private Tags / Manage’.
        </p>
      </div>
    ) : (
      <div>
        <p>
          Aktivoi tagit, joilla haluat poimia ehdotukset, joille on merkitty
          kaikki tai yksi valitsemistasi tageista. Valintapainikkeet osoittavat
          valintoihin osuvien ehdotusten lukumäärän. Tagin ja kieltomerkin
          aktivointi estää ko. ehdotukset suodatustuloksesta.
        </p>
        <p>
          Voit luoda ja muokata ehdotuskohtaisia tageja ehdotuksen
          planssinäkymässä kohdassa: ‘Minun yksityiset tagit Hallinnoi’.
        </p>
      </div>
    )

  return (
    <Wrapper>
      <i className="icon-explanation">
        <span>{t('What does the')} </span>
        <RiProhibitedLine style={{ width: 17, height: 17 }} />
        <span>{t(' do')}?</span>
      </i>
      <Tooltip
        title={infoIconContent}
        aria-label={infoIconContent}
        placement="left-start"
        TransitionComponent={Zoom}
        style={{ fontSize: 20 }}
        classes={{ tooltip: 'custom-tooltip' }}
      >
        <InfoOutlineIcon />
      </Tooltip>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5;
  }

  .icon-explanation {
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .custom-tooltip {
    font-size: 20px;
  }
`
