import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom'
import { getFirestore } from 'redux-firestore'
import cookies from 'react-cookies'
import styled from 'styled-components'

import {
  filterProposalsByPrivateTags,
  setIsFilteredProposals,
  setStoredOptionValue
} from '../../redux/actions/proposalsActions'
import FilterOptions from '../../inputs/FilterOptions'
import { FILTER_PROPOSALS_COOKIE_KEY } from '../../constant'
import useFilterOptions from './useFilterOptions'

const DEFAULT_OPTIONS = [
  {
    id: 1,
    label: 'All proposals',
    value: 1,
    type: 'all'
  },
  {
    id: 2,
    label: 'Exclude Tags',
    value: 2,
    type: 'exclude',
    selectedTags: []
  }
]

const FilterProposalsByPrivateTags = ({
  filterProposalsByPrivateTags,
  userId,
  storedOptionValue,
  setStoredOptionValue,
  setIsFilteredProposals,
  isFilteredProposals
}) => {
  const hasCheckedDefaults = React.useRef(false)
  const firestore = getFirestore()
  const { options, loading, error, deleteOption } = useFilterOptions()

  // Memoize excludedTags to prevent unnecessary re-renders
  const excludedTags = React.useMemo(() => {
    const excludeOption = options.find(option => option.type === 'exclude')
    return excludeOption?.selectedTags || []
  }, [options])

  // Memoize filterProposalsByPrivateTags to ensure a stable reference
  const memoizedFilterProposalsByPrivateTags = React.useCallback(
    (option, tags) => {
      filterProposalsByPrivateTags(option, tags)
    },
    [filterProposalsByPrivateTags]
  )

  React.useEffect(() => {
    if (!loading && options?.length > 0 && !isFilteredProposals) {
      const storedValue = parseInt(storedOptionValue, 10)
      const storedOption = options.find(option => option.value === storedValue)
      if (storedOption) {
        memoizedFilterProposalsByPrivateTags(storedOption, excludedTags)
        setIsFilteredProposals(true)
      } else {
        console.warn('Stored option not found.')
      }
    }
  }, [
    loading,
    options,
    storedOptionValue,
    excludedTags,
    memoizedFilterProposalsByPrivateTags,
    setIsFilteredProposals,
    isFilteredProposals
  ])

  const handleChange = event => {
    const selectedValue = parseInt(event.target.value, 10)
    setStoredOptionValue(selectedValue)
    cookies.save(FILTER_PROPOSALS_COOKIE_KEY, selectedValue, { path: '/' })

    const selectedOption = options.find(
      option => option.value === selectedValue
    )
    if (selectedOption) {
      memoizedFilterProposalsByPrivateTags(selectedOption, excludedTags)
    } else {
      console.warn('Selected option not found.')
    }
  }

  React.useEffect(() => {
    let isMounted = true
    if (!hasCheckedDefaults.current) {
      const checkAndAddDefaultOptions = async () => {
        try {
          const batch = firestore.batch()
          const collectionRef = firestore
            .collection('users')
            .doc(userId)
            .collection('filterOptions')

          for (const option of DEFAULT_OPTIONS) {
            const docRef = collectionRef.doc(option.id.toString())
            const doc = await docRef.get()
            if (!doc.exists) {
              batch.set(docRef, option)
            }
          }

          await batch.commit()
          if (isMounted) {
            hasCheckedDefaults.current = true
          }
        } catch (error) {
          console.error('Error adding default options:', error)
          // Optionally, handle the error or retry logic here
        }
      }

      checkAndAddDefaultOptions()
    }
    return () => {
      isMounted = false
    }
  }, [firestore, userId])

  if (loading) return <StyledLoading>Loading...</StyledLoading>
  if (error) return <div>Error: {error}</div>

  return (
    <div>
      <FilterOptions
        onChange={handleChange}
        value={parseInt(storedOptionValue, 10)}
        options={options}
        deleteOption={deleteOption}
        fixedWidth
      />
    </div>
  )
}

const mapStateToProps = state => ({
  profile: state.firebase.profile,
  proposals: state.proposals.initData,
  userId: state.firebase.auth.uid,
  storedOptionValue:
    state.proposals.storedOptionValue ||
    parseInt(cookies.load(FILTER_PROPOSALS_COOKIE_KEY), 10) ||
    1,
  isFilteredProposals: state.proposals.isFilteredProposals
})

export default compose(
  connect(mapStateToProps, {
    filterProposalsByPrivateTags,
    setStoredOptionValue,
    setIsFilteredProposals
  }),
  firestoreConnect([{ collection: 'proposals' }])
)(withRouter(FilterProposalsByPrivateTags))

const StyledLoading = styled.div`
  width: 200px;
  padding: 4px 8px;
  border: 1px solid #ccced0;
  font-size: 12px;
`
