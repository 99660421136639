// TableRow.js
import React from 'react'
import styled from 'styled-components'
import { flexRender } from '@tanstack/react-table'

const Row = styled.tr``

const Cell = styled.td`
  padding: 1rem 0;
  border-bottom: 1px solid #ccced0;
`

const TableRow = ({ row }) => (
  <Row>
    {row.getVisibleCells().map(cell => (
      <Cell key={cell.id}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Cell>
    ))}
  </Row>
)

export default TableRow
