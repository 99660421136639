import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import LgProposal from './LgProposal'
import MdProposal from './MdProposal'
import SmProposal from './SmProposal'
import XsProposalWithName from './XsProposalWithName'
import XsProposal from './XsProposal'
import ListItem from './ListItem'
import InfoTable from './InfoTable'
import VideoCard from './VideoCard'
import * as Helper from '../util/Helper.js'
import LoadingListView from './skeletonLoading/ListView'
import {
  filterProposals,
  sortProposals
} from '../redux/actions/proposalsActions'
import { useTranslation } from 'react-i18next'
import ShortlistedProposalsTable from '../short-list-proposals/shortlisted-propsoals-table/ShortlistedProposalsTable.jsx'

const styles = theme => ({
  toggle: {
    width: '2rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  no: {
    width: '3rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  pseudonym: {
    maxWidth: '7rem !important',
    fontWeight: 700,
    fontSize: '0.875rem',
    marginRight: 10,
    paddingRight: 8
  },
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  textModeColor: {
    color: theme.modeColors.textColor
  },
  gridContainer: {
    display: 'grid',
    gap: '16px'
  },
  xxs: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(88px, 1fr))'
  },
  xs: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(297px, 1fr))'
  },
  sm: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))'
  },
  md: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))'
  },
  lg: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(278px, 1fr))'
  },
  xl: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(448px, 1fr))'
  },
  xxl: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(720px, 1fr))'
  },
  fixColumns: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 210px))'
  }
})

function ListItems({
  Data,
  proposalsOrdered,
  PublicClasses,
  profile,
  expandAll,
  users,
  classes
}) {
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation('proposals')

  if (loading) {
    setTimeout(() => setLoading(false), 500)
  }

  const getListItems = () => {
    return _.map(Data, (value, key) => {
      const proposalClass = Helper.getProposalClass(
        key,
        proposalsOrdered,
        PublicClasses
      )
      const proposalPublicTags = Helper.getProposalPublicTags(
        key,
        proposalsOrdered
      )
      const proposalPublicNote = Helper.getProposalPublicNote(
        proposalsOrdered,
        key
      )
      const proposalPrivateNote = Helper.getProposalPrivateNote(profile, key)
      const proposalPrivateTags = Helper.getProposalPrivateTags(profile, key)
      const proposalPublicRate = Helper.getProposalPublicRate(
        proposalsOrdered,
        key
      )
      const data = {
        key,
        id: key,
        proposal: value,
        proposalClass,
        proposalPublicTags,
        proposalPrivateTags,
        proposalPublicNote,
        proposalPrivateNote,
        expandAll,
        proposalPublicRate,
        proposalsOrdered,
        PublicClasses,
        users
      }

      return <ListItem {...data} />
    })
  }

  return (
    <Grid item xs={12}>
      <div className="d-flex border-bottom ">
        <div className={classes.toggle}></div>
        <div className={classes.no}>{t('No.')}</div>
        <div className={`col xs ${classes.pseudonym}`}>{t('Pseudonym')}</div>
        <div className={classes.class}>{t('Class')}</div>
        <div className="col text-bold sm">{t('Public tags')}</div>
        <div className="col text-bold sm">{t('Public notes')}</div>
        <div className="col text-bold sm">{t('Private tags')}</div>
        <div className="col text-bold sm">{t('Private notes')}</div>
      </div>

      {loading ? <LoadingListView /> : getListItems()}
    </Grid>
  )
}

class ProposalCards extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const { history } = this.props
    if (history.location.search || history.action === 'PUSH') {
      this.props.filterProposals(history.location.search)
    }
  }

  getCardSize = (size, value, key) => {
    const readOnly = process.env.REACT_APP_READ_ONLY
    const {
      proposalsOrdered,
      proposalsData,
      PublicClasses,
      profile,
      videoMode
    } = this.props
    const proposalClass = Helper.getProposalClass(
      key,
      proposalsOrdered,
      PublicClasses
    )
    const proposalPublicTags = Helper.getProposalPublicTags(
      key,
      proposalsOrdered
    )
    const proposalHasNote = Helper.isProposalHasNote(
      key,
      profile,
      proposalsData
    )
    const lastSeen = _.get(profile, `proposals.${key}.lastSeen`, null)
    const data = {
      seen: readOnly ? true : lastSeen,
      key,
      proposal: value,
      proposalClass,
      proposalPublicTags,
      proposalHasNote,
      size,
      isVideoTab: videoMode
    }

    if (videoMode) {
      return <VideoCard {...data} />
    }

    switch (size) {
      case 6:
        return <LgProposal {...data} />
      case 5:
        return <LgProposal {...data} />
      case 4:
        return <LgProposal {...data} />
      case 3:
        return <MdProposal {...data} />
      case 2:
        return <SmProposal {...data} />
      case 1:
        return <XsProposalWithName {...data} />
      case 0:
        return <XsProposal {...data} />
      default:
        return <MdProposal {...data} />
    }
  }

  getGridTemplateColumn = () => {
    const { zoomLevel, classes } = this.props
    if (zoomLevel === 6) {
      return classes.xxl
    }
    if (zoomLevel === 5) {
      return classes.xl
    }
    if (zoomLevel === 4) {
      return classes.lg
    }
    if (zoomLevel === 3) {
      return classes.md
    }
    if (zoomLevel === 2) {
      return classes.sm
    }
    if (zoomLevel === 1) {
      return classes.xs
    }
    if (zoomLevel === 0) {
      return classes.xxs
    }
  }

  render() {
    const {
      zoomLevel,
      Data,
      listMode,
      tableMode,
      videoMode,
      shortlistedProposalsTableMode,
      classes,
      proposalsOrdered,
      PublicClasses,
      profile,
      users,
      expandAll
    } = this.props

    const gridTemplateColumn = this.getGridTemplateColumn()
    const numberOfProposals = Object.keys(Data).length

    return (
      <div
        className={`${classes.gridContainer} ${classes.textModeColor} ${
          numberOfProposals < 20 ? classes.fixColumns : gridTemplateColumn
        } `}
      >
        {!listMode &&
        !tableMode &&
        !videoMode &&
        !shortlistedProposalsTableMode &&
        Data
          ? _.map(Data, (value, key) => {
              return (
                <Link key={key} className="normal-text" to={`/proposal/${key}`}>
                  {this.getCardSize(zoomLevel, value, key)}
                </Link>
              )
            })
          : null}

        {listMode && Data && (
          <ListItems
            Data={Data}
            proposalsOrdered={proposalsOrdered}
            PublicClasses={PublicClasses}
            profile={profile}
            expandAll={expandAll}
            users={users}
            classes={classes}
          />
        )}

        {tableMode && Data && (
          <Grid item xs={12}>
            <InfoTable
              Data={Data}
              proposalsOrdered={proposalsOrdered}
              PublicClasses={PublicClasses}
              users={users}
            />
          </Grid>
        )}

        {shortlistedProposalsTableMode ? <ShortlistedProposalsTable /> : null}

        {videoMode &&
          Data &&
          _.map(Data, (value, key) => {
            return (
              <Grid key={key} item>
                {this.getCardSize(zoomLevel, value, key)}
              </Grid>
            )
          })}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: state.firestore.ordered.users,
  profile: state.firebase.profile,
  PublicClasses: state.firestore.ordered.PublicClasses,
  proposalsOrdered: state.firestore.ordered.proposals,
  proposalsData: state.firestore.data.proposals
})

export default compose(
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' }
  ]),
  connect(mapStateToProps, { filterProposals, sortProposals })
)(withStyles(styles)(ProposalCards))
