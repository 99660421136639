import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'
import styled from 'styled-components'

import TooltipMUI from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import CustomModal, { ModalMain } from '../modal/CustomModal'
import { useTranslation } from 'react-i18next'
import {
  MdEco,
  MdInsertChart,
  MdLocalActivity,
  MdOutlineArchitecture
} from 'react-icons/md'
import { GiModernCity, GiStairsGoal } from 'react-icons/gi'

const CustomModalMain = styled(ModalMain)`
  width: 1000px;
  padding: 4rem 1rem;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
  }
`

const strokeColors = [
  '#8884d8',
  '#61be65',
  '#f3e200',
  '#ffa632',
  '#ff4c4c',
  '#D34E24',
  '#3f51b5',
  '#61a8be',
  '#bc61be',
  '#dedede',
  '#a74c32',
  '#b6a55c',
  '#ffa8ad',
  '#710627',
  '#38726C',
  '#BAA898',
  '#A57548',
  '#34435E',
  '#AA6373',
  '#03440C',
  '#FF8552',
  '#39393A',
  '#DF3B57',
  '#717744'
]

const renderLines = (arr, opacity) =>
  arr.map((reviewer, i) => (
    <Line
      key={reviewer}
      type="monotone"
      dataKey={reviewer}
      stroke={strokeColors[i]}
      strokeWidth="2"
      strokeOpacity={opacity[reviewer]}
    />
  ))

const renderCustomAxisTick = ({ x, y, payload }) => {
  let svg = ''

  switch (payload.value) {
    case 'Total':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Yleisarvio"
          placement="bottom"
        >
          <>
            <MdInsertChart size="1.5rem" x={x - 10} y={y + 8} />
          </>
        </TooltipMUI>
      )
      break
    case 'Architectural quality and integration with the cityscape and urban structure':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Architectural quality and integration with the cityscape and urban structure"
          placement="bottom"
        >
          <>
            <MdOutlineArchitecture size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Feasibility and project costs':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Feasibility and project costs"
          placement="bottom"
        >
          <>
            <MdInsertChart size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Functionality, flow, and flexibility':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Functionality, flow, and flexibility"
          placement="bottom"
        >
          <>
            <GiStairsGoal size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Sustainability':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Sustainability"
          placement="bottom"
        >
          <>
            <MdEco size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Building concept and its added value for the museum’s mission':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Building concept and its added value for the museum’s mission"
          placement="bottom"
        >
          <>
            <GiModernCity size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break
    case 'Feel and look':
      svg = (
        <TooltipMUI
          TransitionComponent={Zoom}
          title="Feel and look"
          placement="bottom"
        >
          <>
            <MdLocalActivity size="1.5rem" x={x - 10} y={y + 6} />
          </>
        </TooltipMUI>
      )
      break

    default:
      svg = ''
  }

  return svg
}

const createLegendOpacityObj = lineKeyDataArr => {
  const outputObj = {}

  lineKeyDataArr.map(
    reviewer =>
      (outputObj['opacity'] = {
        ...outputObj.opacity,
        [reviewer]: 1
      })
  )

  return outputObj
}

const MultipleLinesChart = ({ isOpen, close, allRates, proposal }) => {
  const lineKeyDataArr = Object.keys(allRates.rates[0]).filter(
    e => e !== 'name'
  )
  const legendOpacityObj = createLegendOpacityObj(lineKeyDataArr)
  const [linesOpacity, setLinesOpacity] = useState(legendOpacityObj)
  const { t } = useTranslation('proposals')

  const handleMouseEnter = o => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 0.5
      }
    })
  }

  const handleMouseLeave = o => {
    // o is default object from recharts and it's available on mouse event
    const { dataKey } = o

    setLinesOpacity({
      opacity: {
        ...linesOpacity.opacity,
        [dataKey]: 1
      }
    })
  }

  return (
    <CustomModal show={isOpen} handleClose={close}>
      <CustomModalMain>
        <h3 style={{ marginTop: '40px' }}>
          {t('Proposal')} {proposal.number} - {proposal.name}
        </h3>

        <LineChart
          width={800}
          height={550}
          data={allRates.rates}
          style={{
            cursor: 'pointer',
            display: 'flex',
            margin: '0 auto'
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            angle={30}
            height={50}
            tick={renderCustomAxisTick}
          />
          <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
          <Tooltip />
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            wrapperStyle={{ right: '0', transform: 'translateX(30%)' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          {/* Lines */}
          {renderLines(lineKeyDataArr, linesOpacity.opacity)}
        </LineChart>
      </CustomModalMain>
    </CustomModal>
  )
}

MultipleLinesChart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  allRates: PropTypes.object.isRequired,
  proposal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]).isRequired
}

export default MultipleLinesChart
