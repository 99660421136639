import React from 'react'

import { RiProhibitedLine } from 'react-icons/ri'

import useFilterOptions from './useFilterOptions'
import styled from 'styled-components'

const ExcludeTag = ({ tagName }) => {
  const {
    options,
    addTagToExcludeOption,
    removeTagFromExcludeOption
  } = useFilterOptions()

  const excludeOption = options.find(option => option.type === 'exclude')
  const isActive = excludeOption?.selectedTags.includes(tagName)

  const clickHandler = event => {
    event.stopPropagation()

    if (isActive) {
      removeTagFromExcludeOption(tagName)
    } else {
      addTagToExcludeOption(tagName)
    }
  }

  return (
    <StyledButton onClick={clickHandler}>
      <RiProhibitedLine
        style={{ width: 20, height: 20, color: isActive ? 'red' : '#d0cfcf' }}
      />
    </StyledButton>
  )
}

export default ExcludeTag

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover {
    background-color: #a3a3a3;
    border-radius: 50%;
  }
`
