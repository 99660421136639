import React from 'react'
import {
  MdEco,
  MdInsertChart,
  MdLocalActivity,
  MdOutlineArchitecture,
  MdOutlineBedroomChild,
  MdOutlineEnergySavingsLeaf,
  MdRoute
} from 'react-icons/md'
import { GiHealthIncrease, GiModernCity, GiStairsGoal } from 'react-icons/gi'
import { TbKeyframes, TbNurse, TbTimeDuration30 } from 'react-icons/tb'
import { BiTrafficCone } from 'react-icons/bi'
import { LuParkingCircle } from 'react-icons/lu'
import { GoWorkflow } from 'react-icons/go'
import { BsBuildingGear } from 'react-icons/bs'

export const rating_fields = [
  {
    name: 'judge_weighted_average_score',
    label: `Total`,
    svg: <MdInsertChart />
  },
  {
    name:
      'architectural_quality_and_integration_with_the_cityscape_and_urban_structure',
    label:
      'Architectural quality and integration with the cityscape and urban structure',
    svg: <MdOutlineArchitecture />
  },
  {
    name: 'feasibility_and_project_costs',
    label: 'Feasibility and project costs',
    svg: <MdInsertChart />
  },
  {
    name: 'functionality_flow_and_flexibility',
    label: 'Functionality, flow, and flexibility',
    svg: <GiStairsGoal />
  },
  {
    name: 'sustainability',
    label: 'Sustainability',
    svg: <MdEco />
  },
  {
    name: 'building_concept_and_its_added_value_for_the_museums_mission',
    label: 'Building concept and its added value for the museum’s mission',
    svg: <GiModernCity />
  },
  {
    name: 'feel_and_look',
    label: 'Feel and look',
    svg: <MdLocalActivity />
  }
]

export const rating_fields_for_specialist = [
  {
    name: 'expert_weighted_average_score',
    label: `Total`,
    svg: <MdInsertChart />
  },
  {
    name: 'tiimi_1_toimintojen_sijoittuminen_ja_yhteydet',
    label: 'tiimi 1 Toimintojen sijoittuminen ja yhteydet',
    svg: <MdRoute />
  },
  {
    name: 'tiimi_1_osastojen_toiminta_hoitohenkilökunnan_näkökulmasta',
    label: 'tiimi 1 Osastojen toiminta hoitohenkilökunnan näkökulmasta',
    svg: <TbNurse />
  },
  {
    name: 'tiimi_1_osastomoduuli_ja_potilashuone',
    label: 'tiimi 1 Osastomoduuli ja potilashuone',
    svg: <MdOutlineBedroomChild />
  },
  {
    name: 'tiimi_1_kuntoutumisen_mahdollisuudet',
    label: 'tiimi 1 Kuntoutumisen mahdollisuudet',
    svg: <GiHealthIncrease />
  },
  {
    name: 'tiimi_2_hälytys-,_henkilö-_ja_huoltoliikenteen_sujuvuus',
    label: 'tiimi 2 Hälytys-, henkilö- ja huoltoliikenteen sujuvuus',
    svg: <BiTrafficCone />
  },
  {
    name: 'tiimi_2_ajoneuvo-_ja_polkupyöräpysäköinti',
    label: 'tiimi 2 Ajoneuvo- ja polkupyöräpysäköinti',
    svg: <LuParkingCircle />
  },
  {
    name: 'tiimi_2_sisälogistiikan_henkilö-_ja_materiaalivirrat',
    label: 'tiimi 2 Sisälogistiikan henkilö- ja materiaalivirrat',
    svg: <GoWorkflow />
  },
  {
    name: 'tiimi_2_rakennuksen_ylläpito',
    label: 'tiimi 2 Rakennuksen ylläpito',
    svg: <BsBuildingGear />
  },
  {
    name: 'tiimi_3_toteutettavuus_ja_pitkäaikaiskestävyys',
    label: 'tiimi 3 Toteutettavuus ja pitkäaikaiskestävyys',
    svg: <TbTimeDuration30 />
  },
  {
    name: 'tiimi_3_runko-_ja_taloteknisten_järjestelmien_johdonmukaisuus',
    label: 'tiimi 3 Runko- ja taloteknisten järjestelmien johdonmukaisuus',
    svg: <TbKeyframes />
  },
  {
    name: 'tiimi_3_energiatehokkuus',
    label: 'tiimi 3 Energiatehokkuus',
    svg: <MdOutlineEnergySavingsLeaf />
  }
]
