import React from 'react'
import { getFirestore } from 'redux-firestore'

const useFetchFilterOptions = userId => {
  const firestore = getFirestore()
  const [optionsFromFirebase, setOptionsFromFirebase] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!userId) return

    setLoading(true)
    setError(null)

    const unsubscribe = firestore
      .collection('users')
      .doc(userId)
      .collection('filterOptions')
      .onSnapshot(
        snapshot => {
          const optionsData = snapshot.docs.map(doc => doc.data())
          setOptionsFromFirebase(optionsData)
          setLoading(false)
        },
        err => {
          setError(err.message)
          setLoading(false)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [firestore, userId])

  return { optionsFromFirebase, loading, error }
}

export default useFetchFilterOptions
