import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { withRouter } from 'react-router-dom' // Use withRouter for react-router-dom v5
import { useTranslation } from 'react-i18next'

import { filterProposals } from '../../redux/actions/proposalsActions'
import * as searchHelper from '../../util/searchHelper'
import MultipleSelectForPrivateTags from '../../inputs/MultipleSelectForPrivateTags'
import useFilterOptions from '../filter-proposals-by-tag/useFilterOptions'

const findMatchTagsBetweenExcludedAndSelected = (
  excludedTags = [],
  selectedTags = []
) => {
  return selectedTags.filter(tag => excludedTags.includes(tag))
}

const PrivateTags = ({ filterProposals, profile, history, location }) => {
  const { t } = useTranslation('navbar')
  const { options } = useFilterOptions()

  const [selectedTags, setSelectedTags] = React.useState([])
  const [privateTagFilterBy, setPrivateTagFilterBy] = React.useState(null)
  const [matchAllCount, setMatchAllCount] = React.useState(0)
  const [matchAnyCount, setMatchAnyCount] = React.useState(0)
  const [matchExcludeCount, setMatchExcludeCount] = React.useState(0)

  // Ensure excludeOption and excludedTags are defined
  const excludeOption = options.find(option => option.type === 'exclude') || {
    selectedTags: []
  }
  const excludedTags = excludeOption.selectedTags || []

  const matchTags = findMatchTagsBetweenExcludedAndSelected(
    excludedTags,
    selectedTags
  )

  React.useEffect(() => {
    const { search } = location
    if (!search) {
      setSelectedTags([])
      return
    }
    const searchQuery = searchHelper.getSearchQuery(search)
    if (searchQuery.private_tags) {
      const privateTags = searchQuery.private_tags.split(',')
      setSelectedTags(privateTags)
    } else {
      setSelectedTags([])
    }
  }, [location])

  const proposalsArray = React.useMemo(
    () => Object.values(profile.proposals || {}),
    [profile.proposals]
  )

  const calculateCounts = React.useCallback(() => {
    const hasMatchTags = matchTags.length > 0
    const hasSelectedTags = selectedTags.length > 0

    let matchExcludeCount = 0
    let filteredProposals = proposalsArray

    if (hasMatchTags) {
      matchExcludeCount = proposalsArray.filter(proposal =>
        matchTags.every(
          tag => proposal.tags && proposal.tags.some(t => t.name === tag)
        )
      ).length

      // Filter out proposals that match any of the tags in matchTags
      filteredProposals = proposalsArray.filter(
        proposal =>
          !matchTags.some(
            tag => proposal.tags && proposal.tags.some(t => t.name === tag)
          )
      )
    }

    let matchAll = 0
    let matchAny = 0

    if (hasSelectedTags) {
      matchAll = filteredProposals.filter(proposal =>
        selectedTags.every(
          tag => proposal.tags && proposal.tags.some(t => t.name === tag)
        )
      ).length

      matchAny = filteredProposals.filter(proposal =>
        selectedTags.some(
          tag => proposal.tags && proposal.tags.some(t => t.name === tag)
        )
      ).length
    }

    setMatchExcludeCount(matchExcludeCount)
    setMatchAllCount(matchAll)
    setMatchAnyCount(matchAny)
  }, [proposalsArray, selectedTags, matchTags])

  React.useEffect(() => {
    if (selectedTags.length > 0) {
      calculateCounts()
    } else {
      // Reset counts when no tags are selected
      setMatchAllCount(0)
      setMatchAnyCount(0)
      setMatchExcludeCount(0)
    }
  }, [selectedTags, calculateCounts])

  const handleChange = React.useCallback(event => {
    const { value } = event.target
    if (value.length === 0 || value[value.length - 1] !== 'none') {
      setSelectedTags(value)
    }
  }, [])

  const selectAll = React.useCallback(privateTags => {
    const values = privateTags.map(privateTag => privateTag.name)
    setSelectedTags(values)
  }, [])

  const clearAll = React.useCallback(() => setSelectedTags([]), [])

  const applyFilters = React.useCallback(() => {
    const newSearch = searchHelper.updateSearchQuery(
      location.search,
      'private_tags',
      selectedTags
    )

    history.replace({
      pathname: location.pathname,
      search: newSearch
    })

    filterProposals(newSearch, privateTagFilterBy)
  }, [history, location, selectedTags, privateTagFilterBy, filterProposals])

  const privateTags = React.useMemo(() => {
    if (!profile.PrivateTags) return []
    const proposalsArray = Object.values(profile.proposals || {})

    // Build a tag count map
    const tagCounts = {}
    proposalsArray.forEach(proposal => {
      if (proposal.tags) {
        proposal.tags.forEach(tag => {
          if (tagCounts[tag.id]) {
            tagCounts[tag.id].count += 1
          } else {
            tagCounts[tag.id] = { name: tag.name, count: 1 }
          }
        })
      }
    })

    const tags = profile.PrivateTags.map(val => ({
      name: val.name,
      amount: tagCounts[val.id]?.count || 0
    }))

    // Filter tags with amount > 0
    return tags.filter(tag => tag.amount > 0)
  }, [profile.PrivateTags, profile.proposals])

  return (
    <div id="filter-private-tags">
      <MultipleSelectForPrivateTags
        options={privateTags}
        values={selectedTags}
        onChange={handleChange}
        selectAll={() => selectAll(privateTags)}
        clearAll={clearAll}
        placeholder={t('My private tags')}
        apply={applyFilters}
        publicTagFilterBy={privateTagFilterBy}
        setPublicTagFilterBy={setPrivateTagFilterBy}
        matchAllCount={matchAllCount}
        matchAnyCount={matchAnyCount}
        matchExcludeCount={matchExcludeCount}
        matchTags={matchTags}
      />
    </div>
  )
}

const mapStateToProps = ({ firebase, firestore }) => ({
  profile: firebase.profile,
  proposals: firestore.ordered.proposals
})

export default compose(
  connect(mapStateToProps, { filterProposals }),
  firestoreConnect([{ collection: 'proposals' }]),
  withRouter // Wrap the component with withRouter
)(PrivateTags)
