import { getProposalAllRate, getProposalClass } from '../../util/Helper'

const findProposalPublicData = (proposalsPublicData, key) => {
  return proposalsPublicData.find(p => p.id === key)
}

const countTrueValues = juryShortListed => {
  return Object.values(juryShortListed).filter(value => value === true).length
}

const nameToColor = (firstName, lastName) => {
  const fullName = `${firstName} ${lastName}`
  let hash = 0
  for (let i = 0; i < fullName.length; i++) {
    hash = fullName.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).slice(-2)
  }
  return color
}

const generateUserColumnData = (userId, usersData) => {
  const user = usersData.find(user => user.id === userId)
  if (user) {
    const firstNameInitial = user.firstName ? user.firstName[0] : ''
    const lastNameInitial = user.lastName ? user.lastName[0] : ''
    const initials = `${firstNameInitial}${lastNameInitial}`
    const color = nameToColor(user.firstName, user.lastName)
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      initials,
      color
    }
  }
  return null
}

const getUsersColumnData = (juryShortListed, usersData) => {
  return Object.entries(juryShortListed)
    .filter(([_, value]) => value === true)
    .map(([userId, _]) => generateUserColumnData(userId, usersData))
}

export const generateData = (
  proposals,
  proposalsPublicData,
  usersData,
  PublicClasses
) => {
  return Object.entries(proposals).map(([key, proposal]) => {
    const proposalPublicData = findProposalPublicData(proposalsPublicData, key)
    const shortlistingAmount = proposalPublicData?.juryShortListed
      ? countTrueValues(proposalPublicData.juryShortListed)
      : 0
    const users = proposalPublicData?.juryShortListed
      ? getUsersColumnData(proposalPublicData.juryShortListed, usersData)
      : []
    const proposalClass = getProposalClass(
      key,
      proposalsPublicData,
      PublicClasses
    )
    const proposalAllRate = getProposalAllRate(usersData, key)

    return {
      number: proposal.number,
      pseudonym: proposal.name,
      shortlistingAmount,
      users,
      class: proposalClass,
      rating: proposalAllRate.rate
    }
  })
}
