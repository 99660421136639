import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import styled from 'styled-components'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import UserAvatar from './Avatar'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { generateData } from './helpers'

const columnHelper = createColumnHelper()

function ShortlistedProposalsTable({
  proposals,
  proposalsOrdered,
  users,
  PublicClasses
}) {
  // const [data, setData] = React.useState(() => [])
  const { t } = useTranslation('proposals')

  const data = React.useMemo(() => {
    if (proposals.data && proposalsOrdered && users && PublicClasses) {
      const data = generateData(
        proposals.data,
        proposalsOrdered,
        users,
        PublicClasses
      )
      return data
    }
    return []
  }, [proposals.data, proposalsOrdered, users, PublicClasses])

  const columns = [
    columnHelper.accessor('number', {
      cell: info => <NumberCell>{info.getValue()}</NumberCell>,
      header: () => <NoColumnHeader>No.</NoColumnHeader>
    }),
    columnHelper.accessor(row => row.pseudonym, {
      id: 'pseudonym',
      cell: info => <i>{info.getValue()}</i>,
      header: () => <div>{t('Pseudonym')}</div>
    }),
    columnHelper.accessor(row => row.class, {
      id: 'Class',
      cell: info =>
        !!info.getValue() ? (
          <ClassCell bgColor={info.getValue().colour}>
            {info.getValue().class}
          </ClassCell>
        ) : null,
      header: () => <div>{t('Class')}</div>
    }),
    columnHelper.accessor(row => row.rating, {
      id: 'Rating',
      cell: info =>
        !!info.getValue() && !!info.getValue()?.judge_weighted_average_score ? (
          <RatingCell>
            {info.getValue().judge_weighted_average_score.toFixed(1)}
          </RatingCell>
        ) : null,
      header: () => <RatingHead>{t('Rating')}</RatingHead>
    }),
    columnHelper.accessor('shortlistingAmount', {
      header: () => <CenteredText>{t('Shortlisting amount')}</CenteredText>,
      cell: info => <CenteredText>{info.getValue()}</CenteredText>,
      sortDescFirst: true
    }),
    columnHelper.accessor('users', {
      header: () => <div>{t('Users')}</div>,
      cell: info => (
        <FlexContainer>
          {info.getValue().map(user => (
            <UserAvatar
              key={user.id}
              initials={user.initials}
              color={user.color}
            />
          ))}
        </FlexContainer>
      )
    })
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <TableWrapper>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeader key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    proposals: state.proposals,
    activeView: state.proposals.activeView,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsOrdered: state.firestore.ordered.proposals,
    users: state.firestore.ordered.users
  }
}

export default compose(
  firestoreConnect([
    { collection: 'proposals' },
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] }
  ]),
  connect(mapStateToProps)
)(ShortlistedProposalsTable)

const TableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }

  thead th {
    border-bottom: 1px solid #ccced0;
  }

  th,
  td {
    text-align: left;
    padding: 1rem 0;
    border-bottom: 1px solid #ccced0;
  }
`

const NumberCell = styled.div`
  width: 50px;
  font-size: 22px;
  font-weight: bold;
`

const CenteredText = styled.div`
  text-align: center;
  width: 120px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
`

const NoColumnHeader = styled.div`
  width: max-content;
  text-align: center;
`

const ClassCell = styled.div`
  background-color: ${props => props.bgColor};
  padding: 5px;
  border-radius: 0;
  color: white;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RatingHead = styled.div`
  text-align: center;
  width: 70px;
`

const RatingCell = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  width: 70px;
`
