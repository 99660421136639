import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { RiProhibitedLine } from 'react-icons/ri'

import {
  filterProposalsByPrivateTags,
  setStoredOptionValue
} from '../redux/actions/proposalsActions'
import useFilterOptions from '../proposals/filter-proposals-by-tag/useFilterOptions'

const ProposalMatchCount = ({
  matchAllCount,
  matchAnyCount,
  matchExcludeCount,
  values,
  handleClose,
  matchTags
}) => {
  const dispatch = useDispatch()
  const { addOption } = useFilterOptions()
  const { t } = useTranslation('proposals')

  const matchAllText =
    matchAllCount <= 1
      ? t('proposal matches all of the selected tags.')
      : t('proposals match all of the selected tags.')
  const matchAnyText =
    matchAnyCount <= 1
      ? t('proposal has some of the selected tags.')
      : t('proposals have some of the selected tags.')

  if (matchAllCount === 0 && matchAnyCount === 0) {
    return <p className="m-0">{t('No tags have been selected.')}</p>
  }

  const applyFilter = async event => {
    const filterBy = event.target.name
    // save to firebase and save to local storage
    const option = await addOption(filterBy, values)

    // Filter proposals
    if (option) {
      // Filter proposals
      dispatch(filterProposalsByPrivateTags(option, matchTags))
      dispatch(setStoredOptionValue(option.value))
      handleClose()
    } else {
      console.error('Failed to add filter option')
    }
  }

  return (
    <StyledProposalMatchCount>
      {matchExcludeCount ? (
        <div className="exclude">
          {matchExcludeCount} {t(' proposals are')}
          <RiProhibitedLine style={{ width: 20, height: 20, color: 'red' }} />
          {t(' from filtering.')}
        </div>
      ) : null}
      <button
        type="button"
        name="every"
        onClick={applyFilter}
        disabled={matchAllCount === 0}
      >
        <strong>{matchAllCount}</strong> {matchAllText}
      </button>
      <button
        type="button"
        name="any"
        onClick={applyFilter}
        disabled={matchAnyCount === 0}
      >
        <strong>{matchAnyCount}</strong> {matchAnyText}
      </button>
    </StyledProposalMatchCount>
  )
}

export default ProposalMatchCount

const StyledProposalMatchCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;

  button {
    border: 1px solid #3f51b5;
    background-color: white;
    margin: 0;
    padding: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      background-color: #3f51b5;
      color: white;
    }

    &:disabled {
      color: #d0cfcf;
      border-color: #d0cfcf;
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  strong {
    font-weight: 600;
    font-size: 20px;
  }

  .exclude {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`
