import HelsinkiLogo from './assets/icons/helsinki-logo.png'

export const logo = {
  src: HelsinkiLogo,
  alt: 'Helsinki logo'
}

export const competitionName = 'New Museum of Architecture and Design'

export const infoTableHeaders = [
  {
    header: 'Gross floor area (GFA) of the proposal',
    accessor: 'gfa'
  },
  { header: 'Net floor area (NFA) of the proposal', accessor: 'nfa' }
]

export const settingsTabs = [
  { id: 'settings-users', label: 'Users', value: 'users' },
  { id: 'settings-submit-setup', label: 'Submit setup', value: 'submit-setup' },
  { id: 'rating-criteria', label: 'Rating criteria', value: 'rating-criteria' },
  { id: 'settings-tab-setup', label: 'Tab setup', value: 'tab-setup' },
  { id: 'settings-theme', label: 'Theme', value: 'theme' }
]

export const adminRoles = ['admin', 'secretary']

export const normalRoles = ['judge', 'specialist']

export const FILTER_PROPOSALS_COOKIE_KEY =
  'jt-adm-filter-proposals-by-private-tags'
