// TableHeader.js
import React from 'react'
import styled from 'styled-components/macro'
import { flexRender } from '@tanstack/react-table'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

const HeaderCell = styled.th`
  text-align: left;
  cursor: ${props => (props.sortable ? 'pointer' : 'default')};
  user-select: none;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const TableHeader = ({ headerGroup }) => {
  const { t } = useTranslation('proposals')

  return (
    <tr>
      {headerGroup.headers.map(header => (
        <HeaderCell
          key={header.id}
          sortable={header.column.getCanSort()}
          onClick={header.column.getToggleSortingHandler()}
          title={
            header.column.getCanSort()
              ? header.column.getNextSortingOrder() === 'asc'
                ? t('Sort ascending')
                : header.column.getNextSortingOrder() === 'desc'
                ? t('Sort descending')
                : t('Clear sort')
              : undefined
          }
        >
          {!header.isPlaceholder && (
            <HeaderContent>
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getIsSorted() === 'asc' && (
                <FaSortUp style={{ width: 15, height: 15 }} />
              )}
              {header.column.getIsSorted() === 'desc' && (
                <FaSortDown style={{ width: 15, height: 15 }} />
              )}
              {!header.column.getIsSorted() && header.column.getCanSort() && (
                <FaSort style={{ width: 15, height: 15 }} />
              )}
            </HeaderContent>
          )}
        </HeaderCell>
      ))}
    </tr>
  )
}

export default TableHeader
