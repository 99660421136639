import React from 'react'
import _ from 'lodash'
import { LineChart, Line, BarChart, Bar, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'

import SettingsIcon from '@material-ui/icons/Settings'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import Button from '../inputs/Button'
import { rating_fields } from './rating_fields'

const styles = theme => ({
  chartContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    position: 'relative',
    overflowX: 'scroll'
  },
  line: {
    height: '144px',
    width: '1px',
    background: '#e0e2e4'
  },
  toolTip: {
    fontSize: '12px'
  },
  overall_rate: {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    marginLeft: '5px',
    padding: '4px 10px 2px',
    backgroundColor: '#e8e8e8',
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '27px',
    fontSize: '18px'
  }
})

const CustomizeDots = ({ cx, cy, payload, secretary }) => {
  let dotColor
  switch (true) {
    case payload.rate > 0 && payload.rate <= 1:
      dotColor = '#F71E06'
      break
    case payload.rate >= 1 && payload.rate <= 2:
      dotColor = '#F78606'
      break
    case payload.rate >= 2 && payload.rate <= 3:
      dotColor = '#F2E713'
      break
    case payload.rate >= 3 && payload.rate <= 4:
      dotColor = '#87A91F'
      break
    case payload.rate >= 4 && payload.rate <= 5:
      dotColor = '#2A8D08'
      break
    default:
      dotColor = '#F71E06'
      break
  }

  if (cx && cy) {
    return (
      <circle cx={cx} cy={cy} r={3} fill={secretary ? '#353c43' : dotColor} />
    )
  }
  return null
}

const Review = props => {
  const {
    title,
    edit,
    rate,
    classes,
    allRates,
    secretary,
    openRatingModal,
    openAllRatingsModal,
    proposalId
  } = props

  const { t } = useTranslation('proposals')

  const createMarkup = data => {
    return { __html: data }
  }

  const getLines = () => {
    const lines = rating_fields.filter(
      item => item.name !== 'judge_weighted_average_score'
    )

    return lines.map(item => (
      <div key={item.name} className="d-flex flex-column align-items-center">
        <div className={classes.line}></div>
        <Tooltip
          title={t(item.label)}
          aria-label={item.label}
          TransitionComponent={Zoom}
          placement="left-end"
          classes={{ tooltip: classes.toolTip }}
        >
          {typeof item.svg === 'string' ? (
            <div
              className="mt-1"
              dangerouslySetInnerHTML={createMarkup(item.svg)}
            />
          ) : (
            <div className="mt-1">{item.svg}</div>
          )}
        </Tooltip>
      </div>
    ))
  }

  const getLineChartValues = rate => {
    if (rate) {
      const linesChart = rating_fields.filter(
        item => item.name !== 'judge_weighted_average_score'
      )

      return linesChart.map(item => {
        const calculatedRate = ((5 * rate[item.name] - 2.49) / 4.51).toFixed(2)
        const currentRate = rate[item.name] ? calculatedRate : 0
        return { ...item, rate: currentRate }
      })
    }
  }

  const getOverallValue = rate => {
    if (rate) {
      const overall = rate['judge_weighted_average_score']
        ? (rate['judge_weighted_average_score'] / 5) * 100
        : 0
      const other = 100 - overall
      return [{ overall, other }]
    }
  }

  const chartLinearGradientColor = rate => {
    const colors = {
      0: '#F71E06',
      1: '#F71E06',
      2: '#F78606',
      3: '#F2E713',
      4: '#87A91F',
      5: '#2A8D08'
    }

    return colors[rate] || '#3f51b5'
  }

  const chartLinearGradientStopsColor = (min, max) => {
    let stops = {
      color0: '',
      color25: '',
      color50: '',
      color75: '',
      color100: ''
    }

    stops.color0 = chartLinearGradientColor(min)
    stops.color25 = chartLinearGradientColor(
      Math.floor(min + (max - min) * 0.25)
    )
    stops.color50 = chartLinearGradientColor(Math.floor((min + max) / 2))
    stops.color75 = chartLinearGradientColor(
      Math.floor(min + (max - min) * 0.75)
    )
    stops.color100 = chartLinearGradientColor(max)

    return stops
  }

  const lineChartColors = lineChartValues => {
    if (!lineChartValues) return

    const max = Math.max(
      ...lineChartValues.map(val => (val.rate ? Math.round(val.rate) : 2))
    )
    const min = Math.min(
      ...lineChartValues.map(val => (val.rate ? Math.round(val.rate) : 0))
    )
    const stops = chartLinearGradientStopsColor(min, max)

    return stops
  }

  const barChartColors = barChartValues => {
    const min = 1
    const max = barChartValues ? Math.round(barChartValues[0].overall / 20) : 2
    const stops = chartLinearGradientStopsColor(min, max)

    return stops
  }

  // Line chart doesn't connect null
  const removeRateProperty = arr => {
    arr.forEach((obj, i) => {
      const firstWithDot = i === 0 && obj.rate !== 0 && arr[1] === 0
      const lastWithDot =
        i === arr.length - 1 && obj.rate !== 0 && arr[i - 1].rate === 0
      const middleWithDot =
        i > 0 &&
        i < arr.length - 1 &&
        obj.rate !== 0 &&
        arr[i - 1].rate === 0 &&
        arr[i + 1].rate === 0

      if (firstWithDot || lastWithDot || middleWithDot) {
        obj.show_dot = true
      }
    })

    return arr.map(obj => {
      if (obj.rate === 0) {
        delete obj.rate
      }
      return obj
    })
  }

  const lines = getLines()
  const lineChartValues = getLineChartValues(rate)
  const formattedLineChartValues = lineChartValues
    ? removeRateProperty(lineChartValues)
    : []
  const lineChartValuesStopColor = lineChartColors(formattedLineChartValues)

  const lineChartValuesAll = allRates ? getLineChartValues(allRates.rate) : null
  const formattedLineChartValuesAll = lineChartValuesAll
    ? removeRateProperty(lineChartValuesAll)
    : []
  const overallValue = getOverallValue(rate)
  const overallValueAll = allRates ? getOverallValue(allRates.rate) : null
  const barChartStopColor = barChartColors(overallValueAll)

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        {title && (
          <h5 className="mb-0">
            {title}{' '}
            {!secretary &&
            rate['judge_weighted_average_score'] &&
            !_.isEmpty(allRates.rate) ? (
              <span className={classes.overall_rate}>
                {rate['judge_weighted_average_score'].toFixed(1)}
              </span>
            ) : null}
          </h5>
        )}

        <div className="d-flex flex-column align-items-end">
          {edit && (
            <Button
              style={{ padding: '5px' }}
              onClick={openRatingModal}
              size="sm"
            >
              <span className="d-flex align-items-center">
                <span className="mr-1">{t('Manage')} </span>
                <SettingsIcon fontSize="small" />
              </span>
            </Button>
          )}
          {secretary && !!allRates.userVoted && (
            <Button
              style={{ padding: 0 }}
              onClick={openAllRatingsModal}
              size="sm"
            >
              <span className="d-flex align-items-center">
                <span className="mr-1">{t('All ratings')} </span>
                <RemoveRedEyeIcon fontSize="small" />
              </span>
            </Button>
          )}
        </div>
      </div>

      {secretary && allRates && (
        <div className="xs">{`(${allRates.userVoted}/${allRates.users} ${t(
          'reviewers'
        )})`}</div>
      )}

      {lineChartValues.length > 0 && (
        <div>
          <div className="d-flex mt-2 position-relative">
            <div className="pl-1 pr-1 d-flex flex-column align-items-center">
              <div className="d-flex">
                {secretary && allRates && (
                  <BarChart
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                    width={5}
                    height={144}
                    data={overallValueAll}
                  >
                    <Bar dataKey="overall" stackId="a" fill="#353c43" />
                    <Bar dataKey="other" stackId="a" fill="#ececec" />
                  </BarChart>
                )}
                <BarChart
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  width={secretary && allRates ? 5 : 10}
                  height={144}
                  data={overallValue}
                >
                  <defs>
                    {barChartStopColor && (
                      <linearGradient
                        id={`barColor-${proposalId}`}
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{
                            stopColor: barChartStopColor.color0,
                            stopOpacity: 1
                          }}
                        />
                        {barChartStopColor.color25 && (
                          <stop
                            offset="25%"
                            style={{
                              stopColor: barChartStopColor.color25,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        {barChartStopColor.color50 && (
                          <stop
                            offset="50%"
                            style={{
                              stopColor: barChartStopColor.color50,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        {barChartStopColor.color75 && (
                          <stop
                            offset="75%"
                            style={{
                              stopColor: barChartStopColor.color75,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        <stop
                          offset="100%"
                          style={{
                            stopColor: barChartStopColor.color100,
                            stopOpacity: 1
                          }}
                        />
                      </linearGradient>
                    )}
                  </defs>
                  <Bar
                    dataKey="overall"
                    stackId="a"
                    fill={`url(#barColor-${proposalId})`}
                  />
                  <Bar dataKey="other" stackId="a" fill="#ececec" />
                </BarChart>
              </div>

              <Tooltip
                title={t(rating_fields[0].label)}
                aria-label={rating_fields[0].label}
                TransitionComponent={Zoom}
                placement="left-end"
                classes={{ tooltip: classes.toolTip }}
              >
                {typeof rating_fields[0].svg === 'string' ? (
                  <div
                    className="mt-1"
                    dangerouslySetInnerHTML={createMarkup(rating_fields[0].svg)}
                  />
                ) : (
                  <div className="mt-1">{rating_fields[0].svg}</div>
                )}
              </Tooltip>
            </div>

            <div className={classes.chartContainer}>
              {lines}
              {secretary && allRates && (
                <div className="position-absolute">
                  <LineChart
                    width={200}
                    height={144}
                    data={formattedLineChartValuesAll}
                  >
                    <Line
                      dot={<CustomizeDots secretary={secretary} />}
                      type="monotone"
                      dataKey="rate"
                      stroke="#353c43"
                      strokeWidth="1"
                      isAnimationActive={false}
                    />
                    <YAxis type="number" domain={[0, 5]} hide={true} />
                  </LineChart>
                </div>
              )}
              <div className="position-absolute">
                <LineChart
                  width={200}
                  height={165}
                  data={formattedLineChartValues}
                >
                  <defs>
                    {lineChartValuesStopColor && (
                      <linearGradient
                        id={`lineColor-${proposalId}`}
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                      >
                        <stop
                          offset="0%"
                          style={{
                            stopColor: lineChartValuesStopColor.color0,
                            stopOpacity: 1
                          }}
                        />
                        {lineChartValuesStopColor.color25 && (
                          <stop
                            offset="25%"
                            style={{
                              stopColor: lineChartValuesStopColor.color25,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        {lineChartValuesStopColor.color50 && (
                          <stop
                            offset="50%"
                            style={{
                              stopColor: lineChartValuesStopColor.color50,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        {lineChartValuesStopColor.color75 && (
                          <stop
                            offset="75%"
                            style={{
                              stopColor: lineChartValuesStopColor.color75,
                              stopOpacity: 1
                            }}
                          />
                        )}
                        <stop
                          offset="100%"
                          style={{
                            stopColor: lineChartValuesStopColor.color100,
                            stopOpacity: 1
                          }}
                        />
                      </linearGradient>
                    )}
                  </defs>
                  <Line
                    dot={<CustomizeDots />}
                    type="monotone"
                    dataKey="rate"
                    stroke={`url(#lineColor-${proposalId})`}
                    strokeWidth="4"
                    isAnimationActive={false}
                  />
                  <YAxis type="number" domain={[0, 5]} hide={true} />
                </LineChart>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(Review)
