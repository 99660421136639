import React from 'react'
import { useSelector } from 'react-redux'
import { getFirestore } from 'redux-firestore'
import cookies from 'react-cookies'

import { FILTER_PROPOSALS_COOKIE_KEY } from '../../constant'
import useFetchFilterOptions from './useFetchFilterOptions'

const useFilterOptions = () => {
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const firestore = getFirestore()
  const auth = useSelector(state => state.firebase.auth)
  const proposals = useSelector(state => state.proposals.initData) || []
  const profile = useSelector(state => state.firebase.profile)
  const userProposalsData = React.useMemo(() => profile.proposals || {}, [
    profile.proposals
  ])
  const userId = auth.uid

  // Fetch options from Firebase
  const {
    optionsFromFirebase,
    loading: optionsLoading,
    error: optionsError
  } = useFetchFilterOptions(userId)

  // Filter options
  const filterOptions = React.useCallback(optionsData => {
    return optionsData.filter(option => {
      // Always include the first option
      if (option.id === 1) {
        return true
      }
      // Only show exclude tags option if there are selected tags
      if (
        option.id === 2 &&
        option.selectedTags &&
        option.selectedTags.length > 0
      ) {
        return true
      }
      return option.id !== 2
    })
  }, [])

  const indexedUserProposals = React.useMemo(() => {
    return Object.values(userProposalsData).map(proposal => ({
      ...proposal,
      tagNames: proposal.tags ? proposal.tags.map(tag => tag.name) : []
    }))
  }, [userProposalsData])

  const allProposalsCount = React.useMemo(() => Object.keys(proposals).length, [
    proposals
  ])

  const addAmountToOptions = React.useCallback(
    (optionsData, proposalsData) => {
      proposalsData = proposalsData || []
      const excludeOption = optionsData.find(
        option => option.type === 'exclude'
      )
      const excludeTags = excludeOption?.selectedTags || []

      return optionsData.map(option => {
        if (option.type === 'all') {
          return {
            ...option,
            amount: allProposalsCount
          }
        } else if (option.type === 'exclude') {
          const excludeTagsCount = indexedUserProposals.filter(proposal =>
            option.selectedTags.some(tag => proposal.tagNames.includes(tag))
          ).length
          return {
            ...option,
            amount: allProposalsCount - excludeTagsCount
          }
        } else if (option.type === 'include') {
          const includeTagsCount = indexedUserProposals.filter(proposal => {
            if (excludeTags.some(tag => proposal.tagNames.includes(tag))) {
              return false
            }

            if (option.filterBy === 'any') {
              return option.selectedTags.some(tag =>
                proposal.tagNames.includes(tag)
              )
            } else if (option.filterBy === 'every') {
              return option.selectedTags.every(tag =>
                proposal.tagNames.includes(tag)
              )
            }
            return false
          }).length

          return {
            ...option,
            amount: includeTagsCount
          }
        }
        return option
      })
    },
    [allProposalsCount, indexedUserProposals]
  )

  // Compute amounts
  const computedOptions = React.useMemo(() => {
    if (optionsLoading || optionsError) return []

    const filteredOptions = filterOptions(optionsFromFirebase)
    return addAmountToOptions(filteredOptions, proposals, userProposalsData)
  }, [
    optionsLoading,
    optionsError,
    filterOptions,
    optionsFromFirebase,
    addAmountToOptions,
    proposals,
    userProposalsData
  ])

  React.useEffect(() => {
    setOptions(computedOptions)
    setLoading(optionsLoading)
    setError(optionsError)
  }, [computedOptions, optionsLoading, optionsError])

  const addOption = async (filterBy, selectedTags) => {
    setLoading(true)
    const nextId = optionsFromFirebase ? optionsFromFirebase.length + 1 : 1
    const filterOption = {
      id: nextId,
      label: filterBy === 'any' ? 'Include Tags (Any)' : 'Include Tags (Every)',
      type: 'include',
      filterBy,
      selectedTags,
      value: nextId
    }

    try {
      // 1. Save to Firebase
      await firestore
        .collection('users')
        .doc(userId)
        .collection('filterOptions')
        .doc(String(nextId)) // Use nextId as the document ID
        .set(filterOption)

      // 2. Save to cookies
      cookies.save(FILTER_PROPOSALS_COOKIE_KEY, nextId, { path: '/' })

      return filterOption
    } catch (error) {
      console.error('Error adding filter option: ', error)
      setError(error.message)
      return null // Return null in case of error
    } finally {
      setLoading(false)
    }
  }

  const deleteOption = async option => {
    setLoading(true)
    try {
      await firestore
        .collection('users')
        .doc(userId)
        .collection('filterOptions')
        .doc(String(option.id))
        .delete()

      // Check and update the cookie value
      const currentCookieValue = cookies.load(FILTER_PROPOSALS_COOKIE_KEY)
      if (currentCookieValue === String(option.value)) {
        cookies.save(FILTER_PROPOSALS_COOKIE_KEY, 1, { path: '/' })
      }
      setOptions(options.filter(o => o.id !== option.id))
    } catch (error) {
      console.error('Error deleting filter option: ', error)
      setError(error.message)
      return null
    } finally {
      setLoading(false)
    }
  }

  const addTagToExcludeOption = async tag => {
    setLoading(true)
    try {
      await firestore.runTransaction(async transaction => {
        const excludeOptionRef = firestore
          .collection('users')
          .doc(userId)
          .collection('filterOptions')
          .doc('2')
        const excludeOptionDoc = await transaction.get(excludeOptionRef)

        if (!excludeOptionDoc.exists) {
          throw new Error('Exclude option not found.')
        }

        const excludeOption = excludeOptionDoc.data()
        const selectedTags = [...(excludeOption.selectedTags || []), tag]

        transaction.update(excludeOptionRef, { selectedTags })
      })
    } catch (error) {
      console.error('Error adding tag to exclude option: ', error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const removeTagFromExcludeOption = async tagName => {
    setLoading(true)
    try {
      await firestore.runTransaction(async transaction => {
        const excludeOptionRef = firestore
          .collection('users')
          .doc(userId)
          .collection('filterOptions')
          .doc('2')
        const excludeOptionDoc = await transaction.get(excludeOptionRef)

        if (!excludeOptionDoc.exists) {
          throw new Error('Exclude option not found.')
        }

        const excludeOption = excludeOptionDoc.data()
        const selectedTags = (excludeOption.selectedTags || []).filter(
          tag => tag !== tagName
        )

        transaction.update(excludeOptionRef, { selectedTags })
      })
    } catch (error) {
      console.error('Error removing tag from exclude option: ', error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    options,
    addOption,
    deleteOption,
    loading,
    error,
    addTagToExcludeOption,
    removeTagFromExcludeOption
  }
}

export default useFilterOptions
