import React from 'react'
import styled from 'styled-components'
import cookies from 'react-cookies'
import { useTranslation } from 'react-i18next'

// import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'

import { GoTrash } from 'react-icons/go'
import { FILTER_PROPOSALS_COOKIE_KEY } from '../constant'

const DeleteFilterOption = ({ option, deleteOption }) => {
  const { t } = useTranslation('proposals')
  const currentCookieValue = cookies.load(FILTER_PROPOSALS_COOKIE_KEY)

  const isActiveOption = currentCookieValue === String(option.value)

  const clickHandler = event => {
    event.preventDefault()
    event.stopPropagation()
    deleteOption(option)
  }

  return (
    <Tooltip
      title={
        isActiveOption
          ? t(
              'This option is currently active and cannot be deleted. Please choose a different option first.'
            )
          : t('Delete')
      }
    >
      <div>
        <StyledButton
          type="button"
          onClick={clickHandler}
          disabled={isActiveOption}
        >
          <GoTrash
            style={{
              width: 15,
              height: 15,
              color: isActiveOption ? '#000000' : '#c1121f'
            }}
          />
        </StyledButton>
      </div>
    </Tooltip>
  )
}

export default DeleteFilterOption

const StyledButton = styled.button`
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: transparent;
  margin: 0;
  margin-left: 10px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.2);
    border: ${({ disabled }) =>
      disabled ? '1px solid #000' : '1px solid #c1121f'};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
