// UserAvatar.js
import React from 'react'
import styled from 'styled-components'

const Avatar = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${props => props.color};
  font-weight: bold;
  color: #000;
`

const UserAvatar = ({ initials, color }) => (
  <Avatar color={color}>{initials}</Avatar>
)

export default UserAvatar
